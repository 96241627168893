<template>
    <div class="menu">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn dark icon v-bind="attrs" v-on="on">
                <v-icon :color="color" :size="size">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
             <v-list class="py-0">
              <v-list-item  link>
              <v-icon size="19" class="mb-1">mdi-trash-can-outline</v-icon>
              <v-list-item-title @click = 'removeAddress' class="py-0">حذف آدرس</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          </div>
</template>
<style lang="scss" scoped>
.v-list-item{
  min-height: 35px !important;
  .v-list-item__title{
    font-size: 13px !important;
  }
}
</style>
<script>
import { deletedAddressReciver } from '../../models/Basket'
export default {
  props: ['color', 'size', 'receiver'],
  methods: {
    removeAddress () {
      deletedAddressReciver(this.$props.receiver.id)
    }
  }
}
</script>