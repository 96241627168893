<template>
    <div class="light-overlay d-flex align-center justify-center">
      <loading/>
    </div>
</template>
<script>
import loading from '@/components/tools/loading'
export default {
    components: {
        loading
    }
}
</script>